import React from 'react';
import {
  Small,
  Box,
  PageContainer,
  Row,
  Col
} from '@tourlane/tourlane-ui';


const col_parent = (col_num) => {
  if (col_num === 2) return [12, 6, 4, 3, 3];
  if (col_num === 3) return [12, 4, 4, 3, 3];
  if (col_num === 4) return [12, 3, 3, 3, 3];

  return [12, 4, 4, 4, 4];
};

const col_icon = (col_num) => {
  if (col_num === 2) return [2.5, 5, 3.9, 3, 2.5];
  if (col_num === 3) return [2.5, 6.5, 5.5, 4.5, 3.5];
  if (col_num === 4) return [3, 9, 7.5, 5.8, 4.8];

  return [2.5, 6.5, 5.5, 4.5, 3.5];
};
const col_txt = (col_num) => {
  if (col_num === 2) return [5, 6, 10, 10, 9];
  if (col_num === 3) return [7, 5.5, 10, 12, 10];
  if (col_num === 4) return [8, 8, 12, 11, 12];

  return [2.5, 5.5, 5.5, 5.5, 5.5];
};

const get_col = (item, index, colcnt) => (
  <Col
    key={index}
    xs={col_parent(colcnt)[0]}
    sm={col_parent(colcnt)[1]}
    md={col_parent(colcnt)[2]}
    lg={col_parent(colcnt)[3]}
    xl={col_parent(colcnt)[4]}
  >
    <Row center="xs">
      <Col
        xs={col_icon(colcnt)[0]}
        sm={col_icon(colcnt)[1]}
        md={col_icon(colcnt)[2]}
        lg={col_icon(colcnt)[3]}
        xl={col_icon(colcnt)[4]}
        style={{ flexBasis: 0, maxWidth: 'none' }}
      >
        <Box width={{ xs: 104, sm: 104, lg: 115 }}>{item.icon}</Box>
      </Col>
    </Row>
    <Row center="xs">
      <Col
        xs={col_txt(colcnt)[0]}
        sm={col_txt(colcnt)[1]}
        md={col_txt(colcnt)[2]}
        lg={col_txt(colcnt)[3]}
        xl={col_txt(colcnt)[4]}
      >
        <Small bold={true} textAlignCenter={true}>
          {item.text}
        </Small>
      </Col>
    </Row>
  </Col>
);

const IconList = (props) => {
  const rowcols = props.ICON_LIST.length / 2;
  const row1cols = Math.ceil(rowcols);
  const row2cols = Math.floor(rowcols);
  const row1 = props.ICON_LIST.slice(0, row1cols);
  const row2 = props.ICON_LIST.slice(row1cols);
  return (
    <PageContainer>
      <Row center="xs" spacing={90}>
        {row1.map((i, index) => get_col(i, index, row1cols))}
      </Row>
      <Row center="xs" spacing={90}>
        {row2.map((i, index) => get_col(i, index, row2cols))}
      </Row>
    </PageContainer>
  );
};

export default IconList;
