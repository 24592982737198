import React from 'react';
import styled from 'styled-components';
import { Container, Show, Link, Base, ExtraSmall } from '@tourlane/tourlane-ui';
import breakpoints from '@tourlane/tourlane-ui/dist/breakpoints';
import CountrySelector from './CountrySelector';

const StyledContainer = styled(Container)`
    display: grid;
    grid-template-columns: calc(100% - 153px) auto;
    justify-content: space-between;
    border-top: solid 1px #edeff0;
    @media (max-width: ${breakpoints.md.max}px) {
        grid-template-columns: calc(100% - 142px) auto;
    }
    @media (max-width: ${breakpoints.sm.max}px) {
        grid-template-columns: calc(100% - 131px) auto;
    }
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: ${breakpoints.sm.max}px) {
        flex-direction: column;
    }
`;

const LineDiv = styled.div`
    width: 1px;
    height: 20px;
    margin: 8px 15px 6px 8px;
    background-color: #edeff0;
`;

const StyledExtraSmall = styled(ExtraSmall)`
    margin-right: auto;
`;

export default function LowerFooter() {
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <StyledContainer>
            <FlexDiv>
                <Show above="md">
                    <CountrySelector />
                    <LineDiv />
                </Show>
                <StyledExtraSmall>
                    <Link variant="dark" href="https://www.tourlane.com/imprint/">Imprint</Link>
                </StyledExtraSmall>
                <StyledExtraSmall>
                    <Link variant="dark" href="https://www.tourlane.com/privacy-policy/">Privacy Policy</Link>
                </StyledExtraSmall>
                <StyledExtraSmall>
                    <Link variant="dark" href="https://www.tourlane.com/terms-and-conditions/">T&C</Link>
                </StyledExtraSmall>
                <StyledExtraSmall>
                    <Link variant="dark" href="https://www.tourlane.com/general-travel-information/">Travel Information</Link>
                </StyledExtraSmall>
                <StyledExtraSmall>
                    <Link variant="dark" href="https://www.tourlane.com/mobile-terms-and-conditions/">Mobile Terms & Conditions</Link>
                </StyledExtraSmall>
                <StyledExtraSmall>
                    <Link variant="dark" href="">Cookie Preferences</Link>
                </StyledExtraSmall>
            </FlexDiv>
            <button style={{ display: 'flex', border: 'none', backgroundColor: 'transparent', alignItems: 'center' }} onClick={scrollToTop}>
                <Base>Back To Top</Base>
                <svg width="24" height="24" style={{ marginLeft: '8px' }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 21V6.83l-3.59 3.58L6 9l6-6 6 6-1.41 1.41L13 6.83V21z" fill="#C8CCCF" fillRule="evenodd"/>
                </svg>
            </button>
        </StyledContainer>
    );
}