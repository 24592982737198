import React, { useEffect } from 'react';

const ApplicationForm = (props) => {
  const iframeUrl = window.Grnhse?.Settings.baseURI;

  useEffect(() => {
    let el = document.getElementById('grnhse_app');
    if (el) window.Grnhse?.Iframe.load(props.jobid);
  }, [props.jobid, iframeUrl]);

  return (
    <div
      ref={(ref) => (props.setRef ? props.setRef('appform', ref) : ref)}
      id="grnhse_app"
    ></div>
  );
};

export default ApplicationForm;
