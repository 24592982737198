import React from 'react';
import {
  PageContainer,
  Container,
  H2,
  H4,
  Intro,
  H3,
  H5,
  Big,
  TabPanel,
} from '@tourlane/tourlane-ui';

const StripHorizontal = (props) => {
  const Title1 = props.isTopStrip ? H4 : H5;
  const Title2 = props.isTopStrip ? H2 : H3;
  const Title3 = props.isTopStrip ? Intro : Big;

  return (
    <PageContainer paddingTop={40}>
      <TabPanel
        name={props.name ? props.name : ''}
        autoHide={false}
        ref={(ref) => (props.setRef ? props.setRef(props.name, ref) : ref)}
      >
        <Container className="mt-6 md:mt-8 lg:mt-12 xl:mt-16">
          <Title1 withBottomMargin={true} textAlignCenter>
            {props.title1text}
          </Title1>
          <Title2 withBottomMargin={true} textAlignCenter>
            {props.title2text}
          </Title2>
          <Title3 withBottomMargin={true} textAlignCenter>
            {props.title3text}
          </Title3>
        </Container>
      </TabPanel>
    </PageContainer>
  );
};

export default StripHorizontal;
