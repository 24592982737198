import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Container,
  Show,
  Dropdown,
  Card,
  TextField,
  Row,
  Col,
  Base,
  ExtraSmall,
  useMediaBreakpointsWithSSR,
} from '@tourlane/tourlane-ui';
import SearchIcon from '@tourlane/iconography/Glyphs/Navigation/Search';
import ChevronForward from '@tourlane/iconography/Glyphs/Navigation/ChevronForward';
import { HarvestAPIContext } from '../../services/requests';

const StyledCard = styled(Card)`
  overflow: auto;
  height: 560px;
`;

const JobRow = styled(Row)`
  padding: 20px 10px;
  align-items: center;
  width: 100%;
  margin: 0;
  border-top: 1px solid #edeff0;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  color: #59dd7d;
  border-bottom: none;
  text-decoration: none;
  background-color: unset;
  cursor: pointer;
  transition: color 0.1s ease-out, transform 0.1s ease-out;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
`;

const StyledChevronForward = styled(ChevronForward)`
  width: 24px;
  height: 24px;
  fill: #c9cdd0;
`;

const Title = styled(Base)`
  font-family: SourceSansPro;
  font-weight: 600;
  font-size: 20px;
`;

const SubTitle = styled(ExtraSmall)`
  font-family: SourceSansPro;
  font-size: 18px;
  color: #92969c;
`;

function setDepartments(jobPosts, isMobileDevice) {
  const departmentsArr = jobPosts.map((jobPost) => jobPost.department);
  const uniqueDepartments = [...new Set(departmentsArr)];
  isMobileDevice && uniqueDepartments.unshift('Select a department');
  const uniqueDepartmentsForDropdown = uniqueDepartments.map((department) => ({
    label: department,
    value: department,
  }));
  return [...uniqueDepartmentsForDropdown];
}

export default function JobsTable() {
  const jobPosts = useContext(HarvestAPIContext);
  const isMobileDevice = useMediaBreakpointsWithSSR(
    { xs: true, sm: true, md: true, lg: false },
    false
  );
  const departments = setDepartments(jobPosts, isMobileDevice);
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);
  const [search, setSearch] = useState('');
  function renderLgJobRow(jobPost) {
    return (
      <JobRow alignX="between">
        <Col xs={5} sm={5} md={5} lg={5}>
          <Title>{jobPost.title}</Title>
          <SubTitle>{jobPost.contract_type}</SubTitle>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <Base>{jobPost.department}</Base>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <Base>{jobPost.location}</Base>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3}>
          <Base>
            <StyledLink to={`/application/${jobPost.id}`} variant="primary">
              LEARN MORE <StyledChevronForward />
            </StyledLink>
          </Base>
        </Col>
      </JobRow>
    );
  }
  function renderSmJobRow(jobPost) {
    return (
      <JobRow alignX="between">
        <Col xs={10} sm={7} md={7} lg={7}>
          <Title>{jobPost.title}</Title>
          <SubTitle>{jobPost.contract_type}</SubTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Base style={{ marginRight: '1.5rem' }}>{jobPost.department}</Base>
            <Base>{jobPost.location}</Base>
          </div>
        </Col>
        <Col xs={2} sm={5} md={5} lg={5}>
          <Show above="xs">
            <Base>
              <StyledLink to={`/application/${jobPost.id}`} variant="primary">
                LEARN MORE <StyledChevronForward />
              </StyledLink>
            </Base>
          </Show>
          <Show below="sm">
            <StyledLink to={`/application/${jobPost.id}`} variant="primary">
              <StyledChevronForward />
            </StyledLink>
          </Show>
        </Col>
      </JobRow>
    );
  }
  function filterJobs(jobPost, index) {
    if (
      selectedDepartment === undefined ||
      selectedDepartment === jobPost.department
    ) {
      if (
        search === '' ||
        jobPost.title?.toLowerCase().includes(search.toLowerCase()) ||
        jobPost.department?.toLowerCase().includes(search.toLowerCase()) ||
        jobPost.location?.toLowerCase().includes(search.toLowerCase()) ||
        jobPost.contract_type?.toLowerCase().includes(search.toLowerCase())
      ) {
        return (
          <React.Fragment key={index}>
            <Show above="md">{renderLgJobRow(jobPost)}</Show>
            <Show below="lg">{renderSmJobRow(jobPost)}</Show>
          </React.Fragment>
        );
      }
    }
    return null;
  }
  function changeSelectedDepartment(department) {
    if (department) setSelectedDepartment(department);
    else setSelectedDepartment(undefined);
  }
  function filterTable(e) {
    changeSelectedDepartment(e.detail.department);
  }
  React.useEffect(() => {
    window.addEventListener('filterTable', filterTable);
    return () => window.removeEventListener('filterTable', filterTable);
  }, []);
  return (
    <Container>
      <StyledCard>
        <Row
          style={{
            margin: '0 calc(1.5rem - 8px) 1.5rem calc(1.5rem - 8px)',
            justifyContent: 'center',
          }}
        >
          <Col style={{ marginTop: '1.5rem' }} xs={12} sm={12} md={6} lg={6}>
            <TextField
              name="job-table-search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              icon={<SearchIcon />}
              placeholder="Search by role or keyword"
              shrinkPlaceholder
            />
          </Col>
          <Col style={{ marginTop: '1.5rem' }} xs={12} sm={12} md={6} lg={6}>
            <Dropdown
              placeholder="Filter by department"
              options={departments}
              value={selectedDepartment}
              onChange={(e) => changeSelectedDepartment(e)}
              shrinkPlaceholder
            />
          </Col>
        </Row>
        {jobPosts.map((jobPost, index) => {
          return filterJobs(jobPost, index);
        })}
      </StyledCard>
    </Container>
  );
}
