import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = `https://boards-api.greenhouse.io/v1/boards/tourlanegmbh/`;
const api = axios.create({ baseURL });

export const get_departments = (callback) => {
  api.get('departments').then(
    (res) => {
      if (!res) return callback({ error: 'no response' });
      if (!res.data) return callback({ error: 'no data in response' });
      if (!res.data.departments)
        return callback({ error: 'no departments in response' });

      let arr = Array.isArray(res.data.departments) ? res.data.departments : [];

      callback(null, arr);
    },
    (err) => callback(err)
  );
};

const HarvestAPIContext = createContext();
const HarvestAPIContextProvider = ({ children }) => {
  const [jobPosts, setJobPosts] = useState([]);

  useEffect(() => {
    const fetchUser = () => {
      fetch('/.netlify/functions/harvest-api-jobs')
        .then((response) => response.json())
        .then((result) => setJobPosts(result))
        .catch((error) => console.error(error));
    };
    fetchUser();
  }, []);
  return (
    <HarvestAPIContext.Provider value={jobPosts}>
      {children}
    </HarvestAPIContext.Provider>
  );
};
export { HarvestAPIContext, HarvestAPIContextProvider };
