import React, { useState, useRef } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import {
  PageContainer,
  Tabs,
  TabsContainer,
  TabList,
  Tab,
  Button,
  zIndexValue,
  Logo,
  Image,
  Hero,
  height,
  Show,
  Container,
  useDidUpdateEffect,
} from '@tourlane/tourlane-ui';
import useScrollSpy from 'react-use-scrollspy';

const VERTICAL_PADDING = 2;

const scrollToRef = (
  ref,
  offset = 0,
  onScrollStart = () => { },
  onScrollEnd = () => { }
) => {
  const currentY = window.scrollY;
  const newY = ref.offsetTop - offset;
  const track = throttle(() => {
    if (
      (newY <= currentY && window.scrollY <= newY) ||
      (newY >= currentY && window.scrollY >= newY)
    ) {
      stop();
    }
  }, 100);
  const stop = () => {
    document.removeEventListener('scroll', track);
    onScrollEnd();
  };
  const start = () => {
    document.addEventListener('scroll', track);
    onScrollStart();
    window.scrollTo({ left: 0, top: newY, behavior: 'smooth' });
  };

  start();
};
const TabsProvider = ({ children, defaultSelected }) => {
  const [tab, setTab] = useState(defaultSelected);
  const stickyRef = useRef(null);
  const [stickyHeight, setStickyHeight] = useState(0);
  const refs = useRef({
    tourlane: null,
    values: null,
    benefits: null,
    positions: null,
  }).current;
  const scrollInProgress = useRef(false);
  const [sectionRefs, setSectionRefs] = useState([]);
  const section = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -stickyHeight - VERTICAL_PADDING - 1,
  });
  const sections = ['tourlane', 'values', 'benefits', 'positions'];
  const selectTab = (name) => {
    if (scrollInProgress.current) {
      return;
    }
    setTab(name);
  };
  useDidUpdateEffect(() => {
    selectTab(sections[section]);
  }, [section]);
  useDidUpdateEffect(() => {
    const heightNew =
      (stickyRef.current && stickyRef.current.content.clientHeight) || 0;
    if (heightNew !== stickyHeight) {
      setStickyHeight(heightNew);
    }
  }, [stickyRef.current]);
  const setRef = (name, ref) => {
    refs[name] = ref;
    if (
      !sectionRefs.length &&
      refs.tourlane &&
      refs.values &&
      refs.benefits &&
      refs.positions
    ) {
      setSectionRefs([
        { current: refs.tourlane },
        { current: refs.values },
        { current: refs.benefits },
        { current: refs.positions },
      ]);
      if (defaultSelected === 'positions') {
        setTimeout(function () {
          setTab('positions');
          scrollTo('positions', 80);
        }, 1000);
      }
    }
  };
  const onScrollStart = () => {
    scrollInProgress.current = true;
  };
  const onScrollEnd = () => {
    scrollInProgress.current = false;
  };
  const scrollTo = (name, vertical_padding_offset = 0) => {
    return refs[name]
      ? scrollToRef(
        refs[name],
        stickyHeight + VERTICAL_PADDING + vertical_padding_offset,
        onScrollStart,
        onScrollEnd
      )
      : null;
  };
  return children({
    tab,
    selectTab,
    stickyRef,
    refs,
    scrollTo,
    setRef,
    stickyHeight,
  });
};
const ImageContainer1 = styled.div`
  position: relative;
  width: 100vw;

  ${height({ xs: '200px', sm: '250px', md: '400px', lg: '550px', xl: '650px' })}
  left: 0;
`;
const ImageContainer = styled.div`
  position: absolute;
  width: 100vw;

  ${height({ xs: '200px', sm: '250px', md: '400px', lg: '550px', xl: '650px' })}
  left: 0;
`;

const Strip0NavigationStrip = (props) => (
  <TabsProvider defaultSelected={props.defaultTab}>
    {({ tab, selectTab, stickyRef, scrollTo, setRef }) => (
      <Tabs
        selected={tab}
        onSelect={(newTab) => {
          selectTab(newTab);
          scrollTo(newTab);
        }}
      >
        <StickyContainer>
          <Sticky ref={stickyRef}>
            {({ style }) => (
              <TabsContainer
                style={{
                  ...style,
                  width: '100vw',
                  paddingTop: '10px',
                  zIndex: zIndexValue('tab'),
                }}
              >
                <PageContainer>
                  <TabList fullWidth>
                    <Tab
                      onClick={() => {
                        scrollTo('tourlane', 1000);
                      }}
                      label="Section"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Logo size="small" height={{ xs: 24, md: 24, xl: 24 }} />
                    </Tab>
                    <Show above="md">
                      <Tab name="tourlane">Why Tourlane</Tab>
                      <Tab name="values">Our Values</Tab>
                      <Tab name="benefits">Benefits</Tab>
                    </Show>
                    <Show from="sm" style={{ flex: 1 }}>
                      <Container className="mt-6 md:mt-8 lg:mt-12 xl:mt-16">
                        <Button
                          onClick={() => {
                            scrollTo('positions');
                          }}
                          name="positions"
                          noWrap
                          size="small"
                        >
                          Open Positions
                        </Button>
                      </Container>
                    </Show>
                  </TabList>
                </PageContainer>
                <Show below="sm">
                  <Container className="mt-6 md:mt-8 lg:mt-12 xl:mt-16">
                    <Button
                      onClick={() => {
                        scrollTo('positions');
                      }}
                      name="positions"
                      fullWidth
                      size="small"
                    >
                      Open Positions
                    </Button>
                  </Container>
                </Show>
              </TabsContainer>
            )}
          </Sticky>

          <ImageContainer>
            <Image src="/assets/hero/hero.webp" alt="Preview image" />
          </ImageContainer>
          <ImageContainer1>
            <Hero
              p={32}
              center
              title="Working with Tourlane"
              linkTitle="Learn more"
              onClick={() => {
                selectTab('tourlane');
                scrollTo('tourlane');
              }}
            />
          </ImageContainer1>
          {props.children({ setRef, selectTab, scrollTo })}
        </StickyContainer>
      </Tabs>
    )}
  </TabsProvider>
);

export default Strip0NavigationStrip;
