import React from 'react';
import {
  CardsCarouselWrapper,
  CardsCarousel,
  PageContainer,
  CardInfo,
  ImageResizeAware,
} from '@tourlane/tourlane-ui';
import { get_departments } from '../../services/requests';

var CAREERS_TEMPLATE = [
  {
    name: 'Data & Analytics',
    title: 'Data & Analytics',
    description: 'Building data systems for travel centric customers at scale.',
    src: '/assets/carousel/data.webp',
  },
  {
    name: 'design',
    title: 'Design',
    description:
      'Building travel experiences through design thinking methodologies. ',
    src: '/assets/carousel/design.webp',
  },
  {
    name: 'engineering',
    title: 'Engineering',
    description:
      'Combining travel with innovation and technology to fulfil Tourlane’s vision.',
    src: '/assets/carousel/engineering.webp',
  },
  {
    name: 'finance',
    title: 'Finance',
    description:
      'Ensuring financial success of Tourlane from our employees through to travellers.',
    src: '/assets/carousel/finance.webp',
  },
  {
    name: 'marketing',
    title: 'Marketing',
    description:
      'Engaging with our travellers through various channels and platforms.',
    src: '/assets/carousel/marketing.webp',
  },
  {
    name: 'operations',
    title: 'Operations',
    description: 'Effectively managing Tourlane’s organisational experience.',
    src: '/assets/carousel/operations.webp',
  },
  {
    name: 'people-culture',
    title: 'People and Culture',
    description:
      'Developing Tourlane’s culture as well as hiring and supporting all colleagues.',
    src: '/assets/carousel/people-and-culture.webp',
  },
  {
    name: 'product-management',
    title: 'Product Management',
    description:
      'Creating visionary products for the best Tourlane experience.',
    src: '/assets/carousel/product-management.webp',
  },
  {
    name: 'sales',
    title: 'Sales',
    description:
      'Building adventures with our customers from planning to travelling, and beyond.',
    src: '/assets/carousel/sales.webp',
  },
  {
    name: 'travel-product',
    title: 'Travel Product',
    description:
      'Ensuring our customers have the best experience while travelling. ',
    src: '/assets/carousel/travel-product.webp',
  },
];

const onLinkClick = (department, selectTab, scrollTo, jobCount) => {
  if (jobCount === 0) return;
  selectTab('positions');
  scrollTo('positions');
  window.dispatchEvent(
    new CustomEvent('filterTable', { detail: { department } })
  );
};

class TourlaneCardsCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      careers: [],
      requestedCareers: false,
    };
  }
  componentDidMount = () => {
    if (!this.state.requestedCareers) {
      this.setState({ requestedCareers: true });

      get_departments((err, res) => {
        if (err) return console.log(err);
        this.setState({
          careers: CAREERS_TEMPLATE.map((x) => {
            const gh_dept = res.find((y) => x.title === y.name);
            x['jobcount'] =
              gh_dept && gh_dept.jobs && gh_dept.jobs.length > 0
                ? gh_dept.jobs.length
                : 0;
            return x;
          }).sort((a, b) => b.jobcount - a.jobcount),
        });
      });
    }
  };

  renderCareerCard = (selectTab, scrollTo) =>
    this.state.careers.map((obj) => (
      <CardInfo
        image={<ImageResizeAware alt={obj.title} src={obj.src} />}
        imageSize="small"
        key={obj.name}
        title={obj.title}
        bodyTitle={obj.description}
        bodyLines={4}
        linkTitle={obj.jobcount > 0 ? 'View positions' : 'No positions'}
        onClick={() =>
          onLinkClick(obj.title, selectTab, scrollTo, obj.jobcount)
        }
      />
    ));

  render() {
    return (
      <CardsCarouselWrapper>
        <PageContainer paddingBottom={10}>
          <CardsCarousel fullWidth>
            {this.renderCareerCard(this.props.selectTab, this.props.scrollTo)}
          </CardsCarousel>
        </PageContainer>
      </CardsCarouselWrapper>
    );
  }
}

export default TourlaneCardsCarousel;
