import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HarvestAPIContextProvider } from './services/requests';
import Landing from './components/Landing';
import Application from './components/Application';
import UpperFooter from './components/UpperFooter';
import MiddleFooter from './components/MiddleFooter';
import LowerFooter from './components/LowerFooter';

const App = () => (
  <BrowserRouter>
    <HarvestAPIContextProvider>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/:openings" element={<Landing />} />
        <Route path="application/:id" element={<Application />} />
      </Routes>
      <UpperFooter />
      <MiddleFooter />
      <LowerFooter />
    </HarvestAPIContextProvider>
  </BrowserRouter>
);

export default App;
