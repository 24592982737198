import React from 'react';
import styled from 'styled-components';
import {
  PageContainer,
  Card,
  Row,
  Col,
  H1,
  Base,
  ContentCarousel,
  InfoCardImage,
  ImageResizeAware,
  Box,
  InfoQuote,
  InfoAgent,
} from '@tourlane/tourlane-ui';
import breakpoints from '@tourlane/tourlane-ui/dist/breakpoints';
import employeeQuotes from '../employeeQuotes.json';
import employeeStats from '../employeeStats.json';

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  @media (max-width: ${breakpoints.xs.max}px) {
    :first-child {
      margin-top: 0;
    }
  }
`;

const StyledContentCarousel = styled(ContentCarousel)`
  margin-top: 2rem;
  padding: 20px;
  overflow: hidden;
`;

export default function LifeAtTourlane() {
  return (
    <PageContainer>
      <Row alignX="center">
        {employeeStats.map((ratio, index) => {
          return (
            <StyledCol xs={12} sm={4} md={4} lg={4} key={index}>
              <H1>{ratio.number}</H1>
              <Base>{ratio.category}</Base>
            </StyledCol>
          );
        })}
      </Row>
      <StyledContentCarousel showChevrons showDots={false}>
        {employeeQuotes.map((employee, index) => {
          return (
            <Card size="extra-large" key={index}>
              <Row gutter={0} spacing={0}>
                <Col width={{ xs: '100%', md: '54%' }} order={{ xs: 2, md: 1 }}>
                  <Box p={{ xs: 16, sm: 20, md: 24, lg: 32 }}>
                    <InfoQuote
                      quoteFixedHeight
                      quoteLines={{ xs: 10, sm: 9, md: 8, xl: 7 }}
                      quoteTitle={employee.quote}
                      agent={
                        <InfoAgent
                          profileImageUrl={employee.profileImage}
                          profileImageAlt={`${employee.name} profile picture`}
                          profileName={employee.name}
                          hintTitle={employee.title}
                        />
                      }
                    />
                  </Box>
                </Col>
                <Col width={{ xs: '100%', md: '46%' }} order={{ xs: 1, md: 2 }}>
                  <InfoCardImage
                    imageHeight={{ xs: 280, md: '100%' }}
                    size="extra-large"
                    imagePosition={{ xs: 'top', md: 'right' }}
                    image={
                      <ImageResizeAware
                        src={employee.mainImage}
                        alt="Background"
                      />
                    }
                  ></InfoCardImage>
                </Col>
              </Row>
            </Card>
          );
        })}
      </StyledContentCarousel>
    </PageContainer>
  );
}
