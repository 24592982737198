import * as React from 'react';
import { useParams } from 'react-router-dom';

import Strip0NavigationStrip from './Strip0NavigationStrip';
import Strip1TourlaneCardsCarousel from './Strip1TourlaneCardsCarousel';
import StripHorizontal from './StripHorizontal';
import IconList from './IconList';
import JobsTable from './JobsTable';
import LifeAtTourlane from './LifeAtTourLane';

import WeLoveTravelIcon from '@tourlane/iconography/Icons/CoreValues/WeLoveTravel';
import TakeTheCallIcon from '@tourlane/iconography/Icons/CoreValues/TakeTheCall';
import SetFootOnMarsIcon from '@tourlane/iconography/Icons/CoreValues/SetFootOnMars';
import BeTheCustomerIcon from '@tourlane/iconography/Icons/CoreValues/BeTheCustomer';
import ActHumbleAndRespectfulIcon from '@tourlane/iconography/Icons/CoreValues/ActHumbleAndRespectful';

import MindfulnessIcon from '@tourlane/iconography/Icons/Careers/Mindfulness';
import LearningIcon from '@tourlane/iconography/Icons/Careers/Learning';
import MentalHealthIcon from '@tourlane/iconography/Icons/Careers/MentalHealth';
import InternationalEnvironmentIcon from '@tourlane/iconography/Icons/Careers/InternationalEnvironment';
import OfficeIcon from '@tourlane/iconography/Icons/Careers/Office';
import HealthyLivingIcon from '@tourlane/iconography/Icons/Careers/HealthyLiving';
import DogsIcon from '@tourlane/iconography/Icons/Careers/Dogs';

const ICON_LIST_values = [
  { text: 'We love travel', icon: <WeLoveTravelIcon /> },
  { text: 'Take the call', icon: <TakeTheCallIcon /> },
  { text: 'Set foot on Mars', icon: <SetFootOnMarsIcon /> },
  { text: 'Be the customer', icon: <BeTheCustomerIcon /> },
  { text: 'Act humble & respectful', icon: <ActHumbleAndRespectfulIcon /> },
];

const ICON_LIST_benefits = [
  {
    text: 'Inclusive environment: bring your whole self!',
    icon: <MindfulnessIcon />,
  },
  { text: 'Learning & development', icon: <LearningIcon /> },
  {
    text: 'Multiple offerings for mental health wellbeing',
    icon: <MentalHealthIcon />,
  },
  {
    text: 'Flexibility to work remotely from almost any country',
    icon: <InternationalEnvironmentIcon />,
  },
  {
    text: 'Hybrid working model and office in the heart of Berlin',
    icon: <OfficeIcon />,
  },
  {
    text: 'Fresh fruits, muesli & drinks in the office',
    icon: <HealthyLivingIcon />,
  },
  { text: 'We love having dogs in the office', icon: <DogsIcon /> },
];

export default function Landing() {
  const { openings } = useParams();
  const defaultTab = openings ? 'positions' : 'tourlane';
  return (
    <div>
      <Strip0NavigationStrip defaultTab={defaultTab}>
        {({ setRef, selectTab, scrollTo }) => (
          <>
            <StripHorizontal
              isTopStrip={true}
              name="tourlane"
              setRef={setRef}
              title1text="Work at Tourlane"
              title2text="Join us in making a lasting impact on travel"
              title3text="At Tourlane, we’re all discoverers. The sum of all our professional steps together make a career path. On our journey we’ve built the amazing team that we have today - a team that will be remembered for having changed an entire industry by demanding more and by changing the norms. Pack your bags and get ready to begin your new professional adventure with us."
            />
            <Strip1TourlaneCardsCarousel
              selectTab={selectTab}
              scrollTo={scrollTo}
            />

            <StripHorizontal
              name="values"
              setRef={setRef}
              title1text="What Tourlane Stands For"
              title2text="Our culture & values"
              title3text="Tourlane is rooted in strong beliefs, passion and a set of non-negotiable core values. They help us set sail in the right direction and they serve as our North Star. Company culture is what everyone is doing when nobody is looking. Our values are the foundation for our culture and they ensure cohesion. They sum up some of our core beliefs and form a reliable basis for all processes, decisions and behaviours at Tourlane."
            />
            <IconList ICON_LIST={ICON_LIST_values} />

            <StripHorizontal
              title1text="Life At Tourlane"
              title2text="You belong at Tourlane"
              title3text="Tourlane has a culture of inclusion, diversity, and growth, supported by various groups and resources to help make a difference in your life and ensure that everyone belongs at Tourlane."
            />
            <LifeAtTourlane />

            <StripHorizontal
              name="benefits"
              setRef={setRef}
              title1text="What We Offer"
              title2text="Perks and Benefits"
              title3text="Tourlane offers a wide range of benefits, whether you're part-time, full-time, working from the office or at home. We believe in giving our employees benefits that balances their career development and personal wellbeing."
            />
            <IconList ICON_LIST={ICON_LIST_benefits} />

            <StripHorizontal
              name="positions"
              setRef={setRef}
              title1text="Open Positions"
              title2text="Find your perfect role"
              title3text=""
            />
            <JobsTable />
          </>
        )}
      </Strip0NavigationStrip>
    </div>
  );
}
