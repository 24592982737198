import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Sticky, StickyContainer } from 'react-sticky';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import {
  PageContainer,
  TabsContainer,
  Button,
  Logo,
  Image,
  Hero,
  height,
  useDidUpdateEffect,
  Flex,
} from '@tourlane/tourlane-ui';
import useScrollSpy from 'react-use-scrollspy';

const VERTICAL_PADDING = 2;

const scrollToRef = (
  ref,
  offset = 0,
  onScrollStart = () => {},
  onScrollEnd = () => {}
) => {
  const currentY = window.scrollY;
  const newY = ref.offsetTop - offset;
  const track = throttle(() => {
    if (
      (newY <= currentY && window.scrollY <= newY) ||
      (newY >= currentY && window.scrollY >= newY)
    ) {
      stop();
    }
  }, 100);
  const stop = () => {
    document.removeEventListener('scroll', track);
    onScrollEnd();
  };
  const start = () => {
    document.addEventListener('scroll', track);
    onScrollStart();
    window.scrollTo({ left: 0, top: newY, behavior: 'smooth' });
  };

  start();
};
const StickyProvider = ({ children }) => {
  const [tab, setTab] = useState('top');
  const stickyRef = useRef(null);
  const [stickyHeight, setStickyHeight] = useState(0);

  const refs = useRef({ appform: null }).current;
  const scrollInProgress = useRef(false);
  const [sectionRefs, setSectionRefs] = useState([]);
  const section = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -stickyHeight - VERTICAL_PADDING - 1,
  });
  const sections = ['top', 'appform'];
  const selectTab = (name) => {
    if (scrollInProgress.current) {
      return;
    }
    setTab(name);
  };
  useDidUpdateEffect(() => {
    selectTab(sections[section]);
  }, [section]);
  useDidUpdateEffect(() => {
    const heightNew =
      (stickyRef.current && stickyRef.current.content.clientHeight) || 0;
    if (heightNew !== stickyHeight) {
      setStickyHeight(heightNew);
    }
  }, [stickyRef.current]);

  const setRef = (name, ref) => {
    refs[name] = ref;

    if (!sectionRefs.length && refs.appform && refs.top) {
      setSectionRefs([{ current: refs.appform }, { current: refs.top }]);
      scrollTo('top');
    }
  };
  const onScrollStart = () => {
    scrollInProgress.current = true;
  };
  const onScrollEnd = () => {
    scrollInProgress.current = false;
  };
  const scrollTo = (name) => {
    return refs[name]
      ? scrollToRef(
          refs[name],
          stickyHeight + VERTICAL_PADDING,
          onScrollStart,
          onScrollEnd
        )
      : null;
  };

  return children({ stickyRef, scrollTo, setRef });
};

const HeroContainer = styled.div`
  position: relative;
  width: 100vw;
  margin: 0px 30px 30px 0px;
  ${height({ xs: '200px', sm: '250px', md: '400px', lg: '550px', xl: '650px' })}
`;
const ImageContainer = styled.div`
  position: relative;
  width: 100vw;
  ${height({ xs: '200px', sm: '250px', md: '400px', lg: '550px', xl: '650px' })}
`;

const Strip0NavigationStrip = (props) => {
  const navigate = useNavigate();
  const onLogoClickHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <StickyProvider>
      {({ stickyRef, scrollTo, setRef }) => {
        return (
          <div>
            <StickyContainer>
              <Sticky ref={stickyRef}>
                {({ style }) => (
                  <TabsContainer
                    ref={(ref) => props.setRef('top', ref)}
                    p={2}
                    style={{
                      ...style,
                      width: '100vw',
                      zIndex: 1,
                    }}
                    fixedHeight
                  >
                    <PageContainer fullHeight>
                      <Flex
                        alignItems="center"
                        justifyContent="between"
                        justifyItems="center"
                        fullHeight
                      >
                        <Link to='/' onClick={onLogoClickHandler}>
                          <Logo height={{ xs: 24, md: 24, xl: 24 }} />
                        </Link>
                        <Button
                          onClick={() =>
                            navigate('/positions', { replace: true })
                          }
                          noWrap
                          name="open-positions"
                          size="small"
                        >
                          Open Positions
                        </Button>
                      </Flex>
                    </PageContainer>
                  </TabsContainer>
                )}
              </Sticky>

              <HeroContainer>
                <ImageContainer>
                  <Image
                    ml={32}
                    mr={32}
                    src={`/assets/carousel/${props?.department
                      ?.toLowerCase()
                      ?.replaceAll(' ', '-')}.webp`}
                    alt='Preview image'
                  />
                  <Hero p={10} center title={props?.department} />
                </ImageContainer>
              </HeroContainer>

              <PageContainer paddingTop={60}>
                {props.children({ scrollTo, setRef })}
              </PageContainer>
            </StickyContainer>
          </div>
        );
      }}
    </StickyProvider>
  );
};

export default Strip0NavigationStrip;
