import React, { useState } from 'react';

import { Tooltip } from '@tourlane/tourlane-ui';
import { Icon } from '@tourlane/tourlane-ui';

import { InfoButtonWithDropdown } from '@tourlane/tourlane-ui';

import DEIcon from '@tourlane/iconography/Icons/Flags/DeSmall';
import USIcon from '@tourlane/iconography/Icons/Flags/UsaSmall';
import UKIcon from '@tourlane/iconography/Icons/Flags/UkSmall';
import NLIcon from '@tourlane/iconography/Icons/Flags/NlSmall';
import FRIcon from '@tourlane/iconography/Icons/Flags/FrSmall';
import { ICON_SIZE } from '@tourlane/tourlane-ui';
import DropdownOption from '@tourlane/tourlane-ui/dist/Input/Dropdown/DropdownOption';
import { InfoMenu } from '@tourlane/tourlane-ui';

const MARKETS = {
  US: { icon: USIcon, label: 'English (US)', url: 'https://tourlane.com/' },
  UK: { icon: UKIcon, label: 'English (UK)', url: 'https://tourlane.co.uk/' },
  DE: { icon: DEIcon, label: 'Deutsch', url: 'https://tourlane.de/' },
  NL: { icon: NLIcon, label: 'Nederlands', url: 'https://tourlane.nl/' },
  FR: { icon: FRIcon, label: 'Français', url: 'https://tourlane.fr/' },
};

const MarketProvider = ({ children }) => {
  const [market, setMarket] = useState('US');
  const [opened, setOpened] = useState(false);
  return children({ market, setMarket, opened, setOpened });
};

export default function CountrySelector() {
  return (
    <MarketProvider>
      {({ market, opened, setOpened }) => (
        <Tooltip
          flex
          onToggle={setOpened}
          trigger="click"
          position="top-start"
          multiline
          noPadding
          content={
            <InfoMenu>
              {Object.keys(MARKETS).map((country, idx) => (
                <DropdownOption
                  key={idx}
                  option={{
                    label: MARKETS[country].label,
                    image: (
                      <Icon
                        color={null}
                        size={ICON_SIZE}
                        as={MARKETS[country].icon}
                      />
                    ),
                  }}
                  href={MARKETS[country].url}
                  target="_blank"
                  selected={country === market}
                  inactive={country === market}
                />
              ))}
            </InfoMenu>
          }
        >
          <InfoButtonWithDropdown rotated={opened}>
            <Icon color={null} size={ICON_SIZE} as={MARKETS[market].icon} />
          </InfoButtonWithDropdown>
        </Tooltip>
      )}
    </MarketProvider>
  );
}
