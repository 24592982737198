import React from 'react';
import styled from 'styled-components';
import {
  Container,
  Show,
  H2,
  H5,
  ExtraSmall,
  Link,
} from '@tourlane/tourlane-ui';
import breakpoints from '@tourlane/tourlane-ui/dist/breakpoints';

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #edeff0;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25%;
  :first-child {
    max-width: 35%;
  }
  @media (max-width: ${breakpoints.sm.max}px) {
    :first-child {
      max-width: 40%;
    }
  }
  @media (max-width: ${breakpoints.xs.max}px) {
    width: 100%;
    :first-child {
      max-width: none;
    }
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialDiv = styled.div`
  margin-top: 1rem;
  @media (max-width: ${breakpoints.xs.max}px) {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const SocialLink = styled.a`
  fill: #3e4144;
  margin-right: 1rem;
  width: 24px;
  height: 24px;
`;

const StyledH5 = styled(H5)`
  margin: 1rem 0 6px 0;
  @media (max-width: ${breakpoints.sm.max}px) {
    margin: 0.6rem 0 6px 0;
  }
`;

const StyledExtraSmall = styled(ExtraSmall)`
  margin: 6px 0 0 0;
`;

const renderSocialSection = () => (
  <FlexDiv>
    <H2>Tourlane</H2>
    <Show above="xs">
      <StyledExtraSmall>
        Tourlane brings out your inner explorer. As your personal travel expert,
        we'll help plan you a tailor-made trip packed with moments to remember
        for a lifetime.
      </StyledExtraSmall>
    </Show>
    <SocialDiv>
      <SocialLink
        title="Facebook"
        href="https://www.facebook.com/tourlane/"
        aria-label="Tourlane Facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12zm12.85 6.656v-6.529h1.803l.239-2.25H12.85l.003-1.126c0-.587.056-.9.898-.9h1.127V5.6h-1.802c-2.165 0-2.927 1.091-2.927 2.927v1.35H8.8v2.25h1.35v6.529h2.7z"></path>
        </svg>
      </SocialLink>
      <SocialLink
        title="Instagram"
        href="https://www.instagram.com/tourlane/"
        aria-label="Tourlane Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12zm12-6.4c-1.738 0-1.956.008-2.638.039-.682.03-1.147.139-1.554.297-.42.163-.778.382-1.133.738a3.142 3.142 0 00-.74 1.134c-.158.407-.266.872-.296 1.553-.031.683-.039.9-.039 2.64 0 1.737.008 1.955.039 2.637.031.682.139 1.147.297 1.554.164.42.382.778.738 1.133.356.356.713.576 1.133.74.408.157.873.265 1.554.296.683.032.9.04 2.639.04 1.738 0 1.956-.008 2.638-.04.682-.03 1.147-.139 1.554-.297a3.12 3.12 0 001.133-.739c.356-.355.575-.712.739-1.133.157-.407.265-.872.297-1.554.031-.682.039-.9.039-2.638s-.008-1.956-.039-2.639c-.032-.681-.14-1.146-.297-1.553a3.141 3.141 0 00-.739-1.134 3.128 3.128 0 00-1.133-.738c-.408-.158-.873-.266-1.555-.297-.682-.031-.9-.039-2.638-.039H12z"></path>
          <path d="M11.426 6.753h.575c1.708 0 1.911.006 2.586.037.624.029.962.133 1.188.22.299.117.512.255.736.48.224.223.362.437.478.735.088.226.192.564.22 1.188.032.675.038.878.038 2.586 0 1.708-.006 1.91-.037 2.585-.029.624-.133.963-.22 1.188a1.979 1.979 0 01-.48.735 1.98 1.98 0 01-.735.479c-.225.088-.564.192-1.188.22-.675.031-.878.038-2.586.038-1.71 0-1.912-.007-2.587-.038-.624-.028-.962-.133-1.188-.22a1.982 1.982 0 01-.736-.479 1.984 1.984 0 01-.479-.736c-.088-.225-.192-.564-.22-1.188-.031-.674-.037-.877-.037-2.586 0-1.71.006-1.91.037-2.585.028-.624.132-.963.22-1.189.116-.298.255-.512.479-.736.224-.224.437-.362.736-.479.226-.088.564-.192 1.188-.22.59-.027.82-.035 2.012-.036v.001zm3.99 1.063a.768.768 0 100 1.536.768.768 0 000-1.536zm-3.415.897a3.287 3.287 0 100 6.574 3.287 3.287 0 000-6.574z"></path>
          <path d="M12 9.867a2.133 2.133 0 110 4.266 2.133 2.133 0 010-4.266z"></path>
        </svg>
      </SocialLink>
      <SocialLink
        title="LinkedIn"
        href="https://www.linkedin.com/company/tourlane/"
        aria-label="Tourlane LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12zm8.48-2.061H5.76v8.17h2.72V9.94zm.18-2.528C8.641 6.611 8.068 6 7.138 6 6.209 6 5.6 6.61 5.6 7.411c0 .785.59 1.413 1.503 1.413h.018c.948 0 1.538-.628 1.538-1.413zm9.628 6.014c0-2.51-1.342-3.678-3.131-3.678-1.444 0-2.09.793-2.452 1.35V9.938h-2.72c.036.767 0 8.171 0 8.171h2.72v-4.563c0-.244.018-.488.09-.663.196-.488.644-.993 1.395-.993.985 0 1.378.75 1.378 1.847v4.372h2.72v-4.685z"></path>
        </svg>
      </SocialLink>
      <SocialLink
        title="Xing"
        href="https://www.xing.com/company/tourlanegmbh/"
        aria-label="Tourlane Xing"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <path d="M11.974 24.031C5.361 24.031 0 18.67 0 12.057S5.36.083 11.974.083 23.95 5.443 23.95 12.057c0 6.613-5.361 11.974-11.975 11.974zm1.259-10.821c1.13-2.052 2.316-4.075 3.457-6.147.117-.214.53-.69.373-.934-.091-.143-.614-.093-.953-.093-.408 0-.808-.03-1.009-.02-.422.024-.568.5-.747.823-1.167 2.11-2.487 4.347-3.606 6.39.652 1.172 1.357 2.46 2.018 3.661.156.284.437 1.054.747 1.159.304.102.716.018 1.177.018.393 0 .89.09.99-.13.075-.165-.1-.428-.205-.617-.77-1.391-1.523-2.742-2.242-4.11zm-6.147 1.252H8.9c.37-.045.56-.563.728-.86.535-.94 1.08-1.909 1.57-2.784-.302-.561-.704-1.215-1.047-1.812-.148-.257-.26-.593-.597-.598H7.759c-.098.014-.186.039-.206.131-.048.294.134.46.225.617.212.364.425.706.654 1.102.073.126.304.448.298.579-.003.088-.18.299-.242.411-.511.913-.93 1.673-1.439 2.578-.075.135-.243.291-.187.486.025.085.16.157.224.15z"></path>
        </svg>
      </SocialLink>
      <SocialLink
        title="Kununu"
        href="https://www.kununu.com/de/tourlanegmbh/"
        aria-label="Tourlane Kununu"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
        >
          <path d="M23.95 12.057c0 6.613-5.362 11.974-11.976 11.974C5.361 24.031 0 18.67 0 12.057S5.36.083 11.974.083 23.95 5.443 23.95 12.057zm-7.909 2.788a3.68 3.68 0 00-.948-2.467 3.677 3.677 0 00.922-2.436v-.62a.322.322 0 00-.31-.254h-1.832a.306.306 0 00-.3.254l-.04.62c0 .664-.54 1.206-1.207 1.206l-.62.007c-.428 0-.837.075-1.22.209V5.989a.307.307 0 00-.306-.306H8.347a.306.306 0 00-.306.306v11.388c0 .168.137.306.306.306h1.833c.17 0 .306-.138.306-.306v-2.532a1.227 1.227 0 011.22-1.207h.647c.665 0 1.207.541 1.207 1.207v.618l.007 1.914c0 .168.138.306.306.306h1.832c.17 0 .306-.138.306-.306l.03-1.914v-.618z"></path>
        </svg>
      </SocialLink>
    </SocialDiv>
  </FlexDiv>
);

const renderAboutUs = () => (
  <FlexDiv>
    <StyledH5>ABOUT TOURLANE</StyledH5>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/about-us/">
        Traveling with us
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://careers.tourlane.com/">
        Working with us
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/press/">
        Reporting about us
      </Link>
    </StyledExtraSmall>
  </FlexDiv>
);

const renderDestinations = () => (
  <FlexDiv>
    <StyledH5>DESTINATIONS</StyledH5>
    <StyledExtraSmall>
      <Link
        variant="dark"
        href="https://www.tourlane.com/central-america/costa-rica/"
      >
        Costa Rica
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/africa/south-africa/">
        South Africa
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/africa/tanzania/">
        Tanzania
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/africa/kenya/">
        Kenya
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/africa/namibia/">
        Namibia
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/africa/seychelles/">
        Seychelles
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/europe/italy/">
        Italy
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link variant="dark" href="https://www.tourlane.com/europe/iceland/">
        Iceland
      </Link>
    </StyledExtraSmall>
    <StyledExtraSmall>
      <Link
        variant="dark"
        href="https://www.tourlane.com/north-america/canada/"
      >
        Canada
      </Link>
    </StyledExtraSmall>
  </FlexDiv>
);

const renderTravelPromise = () => (
  <FlexDiv>
    <StyledH5>TOURLANE'S TRAVEL PROMISE</StyledH5>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <picture style={{ margin: '6px 12px 0 0' }}>
        <source
          srcSet={`${process.env.PUBLIC_URL}/assets/man-backpacking/image.webp 1x, ${process.env.PUBLIC_URL}/assets/man-backpacking/image@2x.webp 2x, ${process.env.PUBLIC_URL}/assets/man-backpacking/image@3x.webp 3x`}
          type="image/webp"
          alt="man backpacking"
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/man-backpacking/image.jpg`}
          srcSet={`${process.env.PUBLIC_URL}/assets/man-backpacking/image@2x.jpg 2x, ${process.env.PUBLIC_URL}/assets/man-backpacking/image@3x.jpg 3x`}
          type="image/jpeg"
          alt="man backpacking"
        />
      </picture>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledExtraSmall>Join Tourlane!</StyledExtraSmall>
        <StyledExtraSmall>
          Free cancellation with Tourlane's money-back guarantee and flexible
          rebooking policy.
        </StyledExtraSmall>
        <StyledExtraSmall>
          <Link variant="primary" href="https://www.tourlane.com/rebooking/">
            More information
          </Link>
        </StyledExtraSmall>
      </div>
    </div>
  </FlexDiv>
);

export default function MiddleFooter() {
  return (
    <React.Fragment>
      <Show above="md">
        <StyledContainer>
          {renderSocialSection()}
          {renderAboutUs()}
          {renderDestinations()}
          {renderTravelPromise()}
        </StyledContainer>
      </Show>
      <Show from="sm" to="md">
        <StyledContainer>
          {renderSocialSection()}
          {renderAboutUs()}
          {renderDestinations()}
        </StyledContainer>
      </Show>
      <Show below="sm">
        <StyledContainer>{renderSocialSection()}</StyledContainer>
      </Show>
    </React.Fragment>
  );
}
