import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import styled from 'styled-components';
import { Card, Base, H4, Box, Button } from '@tourlane/tourlane-ui';
import breakpoints from '@tourlane/tourlane-ui/dist/breakpoints';
import Strip0NavigationStrip from './Strip0NavigationStrip';
import { HarvestAPIContext } from '../../services/requests';
import ApplicationForm from './ApplicationForm';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.8fr;
  grid-gap: 2rem;

  @media (max-width: ${breakpoints.sm.max}px) {
    grid-template-columns: 1fr;
  }
`;
const Aside = styled.aside`
  position: sticky;
  top: 120px;
  align-self: start;

  @media (max-width: ${breakpoints.sm.max}px) {
    position: static;
  }
`;

export default function Landing() {
  const { id } = useParams();
  const jobPosts = useContext(HarvestAPIContext);
  const jobPost = jobPosts.find((jobPost) => jobPost.id === parseInt(id));
  return (
    <div>
      <Strip0NavigationStrip department={jobPost?.department}>
        {({ scrollTo, setRef }) => {
          return (
            <>
              <GridContainer>
                <Aside>
                  <Card>
                    <Box p={16}>
                      <H4
                        style={{
                          marginBottom: '0.5rem',
                          fontFamily: 'SourceSansPro',
                        }}
                      >
                        {jobPost?.title || ''}
                      </H4>
                      <Base muted>{jobPost?.contract_type || ''}</Base>
                      <Button
                        onClick={() => scrollTo('appform')}
                        size="small"
                        style={{ width: '100%' }}
                      >
                        Apply Now
                      </Button>
                    </Box>
                  </Card>
                </Aside>
                <Base>
                  <Markup markup={jobPost?.content || ''}/>
                  <ApplicationForm setRef={setRef} jobid={id} />
                </Base>
              </GridContainer>
            </>
          );
        }}
      </Strip0NavigationStrip>
    </div>
  );
}
